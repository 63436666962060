import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import hyperlocalvibeslogo from "../images/hyperlocalvibes_logo.png";

import "../blocks/Login.css";

import CarouselConcerts from "./CarouselConcerts";

import RegistroLogin from "./RegistroLogin";

const Login = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  return (
    <div className="login-page">
      <header className="login-header">
        <div className="header-top">
          <img
            src={hyperlocalvibeslogo}
            alt="HyperLocalVibes Logo"
            className="login-logo"
          />
          <h1 className="header-title">HyperLocal Vibes</h1>
        </div>
        <h2 className="header-subtitle">- Mallorca -</h2>
      </header>

      <main className="login-content">
        <h1 className="login-title">
          Descubre Gratis conciertos a tu medida en Mallorca
        </h1>
        <h2 className="login-subtitle">
          Recomendaciones personalizadas en segundos
        </h2>

        {!message && (
          <div className="login-registration-group">
            <RegistroLogin />
          </div>
        )}

        <CarouselConcerts />
      </main>

      <div className="invite-bands">
        <p>
          ¿Tienes una banda?{" "}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSeedcvrQUtDQ8USAP-F-NTfK-TaP_xIa6GgQiek_W96e_KbFQ/viewform?usp=header"
            target="_blank"
            rel="noopener noreferrer"
          >
            Publica tus conciertos
          </a>
        </p>
      </div>
    </div>
  );
};

export default Login;
