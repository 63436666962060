import React, { useState, useEffect } from "react";
import { Analytics } from "@vercel/analytics/react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { supabase } from "./supabaseClient";

import Login from "./Login";
import Footer from "./Footer";
import AboutUs from "./AboutUs";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";
import Contact from "./Contact";
import RecoverPassword from "./RecoverPassword";

import GenreSelection from "./registration/GenreSelection";
import GenreDislike from "./registration/GenreDislike";
import FavoriteBands from "./registration/FavoriteBands";
import ProfileSetup from "./ProfileSetup";

import MyConcertsPage from "./MyConcertsPage";
import UserProfile from "./UserProfile";
import HeaderLink from "./HeaderLink";

import Header from "./Header";
import RecommendConcerts from "./RecommendConcerts";
import ConcertsList from "./ConcertsList";

import TagManager from "react-gtm-module";

import "../blocks/App.css";

const tagManagerArgs = {
  gtmId: "GTM-T87PQF4J",
};

function App() {
  const [session, setSession] = useState(null);
  const [loadingSession, setLoadingSession] = useState(true);
  const [formData, setFormData] = useState({ genres: [], dislikedGenres: [] });
  const [onboardingCompleted, setOnboardingCompleted] = useState(false);
  const [recommendations, setRecommendations] = useState([]);

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    const checkSession = async () => {
      const { data, error } = await supabase.auth.getSession();

      if (error || !data.session) {
        setLoadingSession(false);
        return;
      }

      setSession(data.session);
      setLoadingSession(false);
    };

    checkSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (_event, session) => {
        setSession(session);
        setLoadingSession(false);
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const completeOnboarding = async () => {
    setOnboardingCompleted(true);
    localStorage.setItem("onboardingCompleted", JSON.stringify(true));

    await supabase
      .from("users")
      .update({ onboarding_completed: true })
      .eq("user_id", session.user.id);
  };

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      // Elimina la información de sesión del localStorage
      localStorage.removeItem("supabase.auth.token");
      setSession(null);
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
      setSession(null);
    }
  };

  return (
    <div>
      <div className="page">
        <Router>
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/conciertos-en-mallorca" replace />}
            />
            <Route
              path="/conciertos-en-mallorca"
              element={
                loadingSession ? null : session ? (
                  localStorage.getItem("isNewUser") === "true" ? (
                    <Login />
                  ) : (
                    <Navigate to="/myconcerts" replace />
                  )
                ) : (
                  <Login />
                )
              }
            />

            <Route
              path="/profile-setup"
              element={<ProfileSetup session={session} />}
            />

            <Route
              path="/liked-music-genres"
              element={
                <>
                  {session && <HeaderLink />}
                  <GenreSelection
                    formData={formData}
                    setFormData={setFormData}
                  />
                </>
              }
            />

            <Route
              path="/disliked-music-genres"
              element={
                <>
                  {session && <HeaderLink />}
                  <GenreDislike formData={formData} setFormData={setFormData} />
                </>
              }
            />

            <Route
              path="/fav-bands"
              element={
                <>
                  {session && <HeaderLink />}
                  <FavoriteBands
                    formData={formData}
                    setFormData={setFormData}
                    completeOnboarding={completeOnboarding}
                  />
                </>
              }
            />

            <Route
              path="/myconcerts"
              element={
                session ? (
                  <>
                    <MyConcertsPage
                      session={session}
                      handleLogout={handleLogout}
                    />
                  </>
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
            <Route
              path="/profile"
              element={
                session ? (
                  <>
                    <HeaderLink session={session} handleLogout={handleLogout} />
                    <UserProfile session={session} />
                  </>
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />

            <Route path="/about" element={<AboutUs />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/recover" element={<RecoverPassword />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </div>
      <Footer />
      <Analytics />
    </div>
  );
}

export default App;
