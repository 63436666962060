import { useState, useEffect } from "react";
import { supabase } from "./supabaseClient";
import { useNavigate } from "react-router-dom";
import "../blocks/RegistroLogin.css";

// import SpotifySignupButton from "./SpotifySignupButton";

export default function RegistroLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isRegistering, setIsRegistering] = useState(true); // Inicia en modo registro
  const navigate = useNavigate();

  useEffect(() => {
    const checkSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error("Error obteniendo sesión:", error);
        return;
      }
      if (data.session) {
        if (window.location.pathname !== "/myconcerts") {
          checkUserInDatabase(data.session.user.id);
        }
      }
    };
    checkSession();
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return; // Evita múltiples envíos

    setLoading(true);
    setError("");

    let response;
    if (isRegistering) {
      // Marca que se está registrando un nuevo usuario
      localStorage.setItem("isNewUser", "true");
      response = await supabase.auth.signUp({ email, password });
    } else {
      response = await supabase.auth.signInWithPassword({ email, password });
    }

    const { error, data } = response;
    if (error) {
      setError(error.message);
    } else {
      console.log("✅ Registro/Login exitoso. Verificando en BD...");
      if (window.location.pathname !== "/myconcerts") {
        checkUserInDatabase(data.user.id);
      }
    }

    setLoading(false);
  };

  const updateLastLogin = async (userId) => {
    const { error } = await supabase
      .from("users")
      .update({ last_login: new Date() })
      .eq("user_id", userId);
    if (error) {
      console.error("Error actualizando last_login:", error);
    }
  };

  const checkUserInDatabase = async (userId) => {
    console.log(`Verificando usuario en la base de datos: ${userId}`);
    const { data, error } = await supabase
      .from("users")
      .select("user_id")
      .eq("user_id", userId)
      .single();

    if (error) {
      console.error("Error verificando usuario en la base de datos:", error);
    }

    if (data) {
      console.log(
        "✅ Usuario encontrado. Actualizando last_login y redirigiendo a /myconcerts..."
      );
      await updateLastLogin(userId);
      if (window.location.pathname !== "/myconcerts") {
        navigate("/myconcerts");
      }
    } else {
      console.log("❌ Usuario no encontrado. Creando nuevo usuario...");
      await createUserInDatabase(userId);
      if (window.location.pathname !== "/liked-music-genres") {
        navigate("/liked-music-genres");
      }
    }
  };

  const createUserInDatabase = async (userId) => {
    const { error } = await supabase
      .from("users")
      .insert([{ user_id: userId, email, last_login: new Date() }]);
    if (error) {
      console.error("Error al crear usuario en la base de datos:", error);
    } else {
      // Limpia la bandera de nuevo usuario
      localStorage.removeItem("isNewUser");
      navigate("/profile-setup");
      console.log("Usuario creado correctamente en la base de datos.");
    }
  };

  // Validación de email
  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  // Validación de contraseña (mínimo 6 caracteres)
  const isValidPassword = (password) => password.length >= 6;

  // Botón desactivado si email o password no son válidos
  const isButtonDisabled =
    !isValidEmail(email) || !isValidPassword(password) || loading;

  return (
    <div className="form-group">
      <h2 className="login-register-title">
        {isRegistering ? "Empieza a disfrutar" : "Inicia sesión"}
      </h2>

      <form onSubmit={handleSubmit} className="login-register-form">
        <input
          type="email"
          className="email-input"
          placeholder="Correo electrónico"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          className="email-input"
          placeholder="Contraseña (mínimo 6 caracteres)"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button
          type="submit"
          className="login-button"
          disabled={isButtonDisabled}
        >
          {loading
            ? "Cargando..."
            : isRegistering
            ? "Regístrate Gratis"
            : "Genial verte de nuevo"}
        </button>
      </form>

      {error && <p className="message error">{error}</p>}

      <p className="toggle-text">
        {isRegistering ? "¿Ya tienes cuenta?" : "¿No tienes cuenta?"}{" "}
        <span onClick={() => setIsRegistering(!isRegistering)}>
          {isRegistering ? "Inicia sesión" : "Regístrate"}
        </span>
      </p>
      {/* Enlace de "¿Olvidaste tu contraseña?" solo cuando está en modo Login */}
      {!isRegistering && (
        <p className="forgot-password">
          <a href="/recover">¿Olvidaste tu contraseña?</a>
        </p>
      )}

      {/* <div className="separator">
        <span>O</span>
      </div>

      <div>
        <SpotifySignupButton />
      </div> */}
    </div>
  );
}
