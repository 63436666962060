import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { supabase } from "./supabaseClient";
import Header from "./Header";
import RecommendConcerts from "./RecommendConcerts";
import ConcertsList from "./ConcertsList";

const MyConcertsPage = ({ session, handleLogout }) => {
  const [loading, setLoading] = useState(true);
  const [onboardingCompleted, setOnboardingCompleted] = useState(false);
  const [recommendations, setRecommendations] = useState([]);

  useEffect(() => {
    if (!session) {
      setLoading(false);
      return;
    }

    // Recuperar `onboardingCompleted` desde localStorage
    const cachedOnboarding = localStorage.getItem("onboardingCompleted");
    if (cachedOnboarding !== null) {
      setOnboardingCompleted(JSON.parse(cachedOnboarding));
      setLoading(false);
    } else {
      checkUserInDatabase(session.user.id);
    }
  }, [session]);

  const checkUserInDatabase = async (userId) => {
    try {
      const { data, error } = await supabase
        .from("users")
        .select("onboarding_completed")
        .eq("user_id", userId)
        .single();

      if (!error && data) {
        setOnboardingCompleted(data.onboarding_completed || false);
        localStorage.setItem(
          "onboardingCompleted",
          JSON.stringify(data.onboarding_completed || false)
        );
      } else {
        setOnboardingCompleted(false);
        localStorage.setItem("onboardingCompleted", JSON.stringify(false));
      }
    } catch {
      setOnboardingCompleted(false);
      localStorage.setItem("onboardingCompleted", JSON.stringify(false));
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <h2>Cargando sesión...</h2>;
  }

  if (!session) {
    return <Navigate to="/" replace />;
  }

  if (!onboardingCompleted) {
    return <Navigate to="/liked-music-genres" replace />;
  }

  return (
    <>
      <Header session={session} handleLogout={handleLogout} />
      <RecommendConcerts
        session={session}
        setRecommendations={setRecommendations}
      />
      <ConcertsList session={session} recommendations={recommendations} />
    </>
  );
};

export default MyConcertsPage;
