import React, { useEffect, useState } from "react";
import { supabase } from "./supabaseClient";
import { useNavigate } from "react-router-dom";
import HeaderLink from "./HeaderLink";
import "../blocks/ProfileSetup.css";

const ProfileSetup = ({ session }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editedData, setEditedData] = useState({});
  const [isModified, setIsModified] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSpotifyData = async () => {
      if (!session?.provider_token) {
        console.error("No Spotify token found.");
        navigate("/");
        return;
      }

      try {
        const [userProfileResponse, topArtistsResponse] = await Promise.all([
          fetch("https://api.spotify.com/v1/me", {
            headers: { Authorization: `Bearer ${session.provider_token}` },
          }),
          fetch(
            "https://api.spotify.com/v1/me/top/artists?time_range=medium_term&limit=10",
            {
              headers: { Authorization: `Bearer ${session.provider_token}` },
            }
          ),
        ]);

        if (!userProfileResponse.ok || !topArtistsResponse.ok) {
          throw new Error("Error fetching Spotify data");
        }

        const userProfile = await userProfileResponse.json();
        const topArtistsData = await topArtistsResponse.json();

        const initialData = {
          spotify_id: userProfile.id,
          name: userProfile.display_name,
          email: userProfile.email,
          country: userProfile.country,
          followers: userProfile.followers.total,
          top_artists: topArtistsData.items
            .map((artist) => artist.name)
            .join(", "),
          top_genres: Array.from(
            new Set(topArtistsData.items.flatMap((artist) => artist.genres))
          ).join(", "),
          disliked_genres: "", // Campo inicial vacío
          profile_image: userProfile.images?.[0]?.url || null,
        };

        setUserData(initialData);
        setEditedData(initialData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching Spotify data:", error);
        navigate("/");
      }
    };

    fetchSpotifyData();
  }, [session, navigate]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedData((prev) => ({ ...prev, [name]: value }));
    setIsModified(true);
  };

  const saveUserToSupabase = async () => {
    if (!userData) return;

    try {
      await supabase
        .from("users")
        .upsert([{ user_id: session.user.id, ...editedData }]);
      console.log("✅ Usuario guardado en Supabase.");
      setIsModified(false);
    } catch (error) {
      console.error("❌ Error al guardar en Supabase:", error);
    }
  };

  if (loading)
    return <h2 className="loading-message">Cargando perfil de Spotify...</h2>;

  return (
    <>
      <HeaderLink />
      <div className="profile-setup-container">
        <h2 className="profile-title">
          Te recomendaremos conciertos según estos gustos musicales
        </h2>
        <p className="profile-subtitle">
          Puedes editarlos para que las recomendaciones sean mejores. También
          puedes pasar de todo e ir directamente a la fiesta.
        </p>
        {userData.profile_image && (
          <img
            src={userData.profile_image}
            alt="Profile"
            className="profile-image"
          />
        )}
        <div className="profile-info">
          <p>
            <strong>Nombre:</strong> {userData.name}
          </p>
          <p>
            <strong>Email:</strong> {userData.email}
          </p>
          <label className="editable-label">
            <strong>Géneros favoritos:</strong>
            <textarea
              name="top_genres"
              value={editedData.top_genres}
              onChange={handleInputChange}
              className="editable-textarea fixed-height"
              placeholder="Dinos dos o tres de tus géneros favoritos"
            />
          </label>

          <label className="editable-label">
            <strong>Artistas favoritos:</strong>
            <textarea
              name="top_artists"
              value={editedData.top_artists}
              onChange={handleInputChange}
              className="editable-textarea fixed-height"
              placeholder="Dinos un par de tus artistas favoritos"
            />
          </label>

          <label className="editable-label">
            <strong>Géneros que no quiero ver en concierto:</strong>
            <textarea
              name="disliked_genres"
              value={editedData.disliked_genres}
              onChange={handleInputChange}
              className="editable-textarea fixed-height"
              placeholder="Esos estilos musicales que no quieres escuchar en directo..."
            />
          </label>
        </div>
        {isModified && (
          <button className="save-button" onClick={saveUserToSupabase}>
            Guardar cambios
          </button>
        )}
        <button className="next-button" onClick={() => navigate("/myconcerts")}>
          Muéstrame los conciertos 🤘
        </button>
      </div>
    </>
  );
};

export default ProfileSetup;
