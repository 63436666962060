import React, { useEffect, useState } from "react";
import { supabase } from "./supabaseClient";
import "../blocks/CarouselConcerts.css";

const CarouselConcerts = () => {
  const [concerts, setConcerts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const messages = [
    "Regístrate gratis y descubre si este concierto te hará saltar del sofá. 🙌",
    "Crea tu cuenta y ve si este show merece un buen headbanging. 🧑‍🎤",
    "Entra gratis y descubre si esta banda será tu próximo himno. 🔥",
    "Regístrate y averigua si este concierto es puro rock o puro bluff. 🤟",
    "No te quedes fuera, únete y averigua si este te va a molar. 🚀",
    "Regístrate gratis y siente si esta música te electriza. 👏",
    "Únete y comprueba si este concierto es un hit o un one-hit wonder. 🫶",
    "Regístrate y descubre si este show merece un encore. 🔥",
    "No te quedes con la duda: entra gratis y siente el riff. 🎸",
    "Únete gratis y descubre si este concierto rompe o se rompe. 🤘",
  ];

  const getRandomMessage = () => {
    return messages[Math.floor(Math.random() * messages.length)];
  };

  useEffect(() => {
    const fetchConcerts = async () => {
      try {
        const today = new Date().toISOString().split("T")[0];
        const { data, error } = await supabase
          .from("concerts")
          .select("id, day, genre, venue, hour, price, bandname")
          .gte("day", today)
          .order("day", { ascending: true })
          .limit(20);

        if (error) throw error;

        const transformedConcerts = data.map((concert) => ({
          id: concert.id,
          day: concert.day,
          genre: concert.genre,
          venue: concert.venue,
          hour: concert.hour,
          price: concert.price,
          bands: concert.bandname,
          message: getRandomMessage(),
        }));

        setConcerts(transformedConcerts);
      } catch (error) {
        console.error("Error fetching concerts:", error);
      }
    };

    fetchConcerts();
  }, []);

  useEffect(() => {
    if (concerts.length === 0) return;
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % concerts.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [concerts]);

  return (
    <div className="carousel-container-custom">
      <h2 className="carousel-title-custom">
        La agenda de conciertos en Mallorca, con recomendaciones personalizadas.
      </h2>
      <div
        className="carousel-track-custom"
        style={{ transform: `translateX(-${currentIndex * 20}%)` }}
      >
        {concerts.map((concert) => (
          <div key={concert.id} className="carousel-card-custom">
            <div className="carousel-date-custom">
              {new Date(concert.day).toLocaleDateString("es-ES", {
                weekday: "long",
                day: "numeric",
                month: "long",
              })}
            </div>
            <h3 className="carousel-band-custom">{concert.bands}</h3>
            {/* <p className="carousel-genre-custom">
              <strong>Género:</strong> {concert.genre}
            </p> */}
            {/* <p className="carousel-venue-custom">
              <strong>Local:</strong> {concert.venue}
            </p>
            <p className="carousel-time-custom">
              <strong>Hora:</strong> {concert.hour}
            </p>
            <p className="carousel-price-custom">
              {concert.price === 0 ? "Gratis" : `${concert.price} €`}
            </p> */}
            <p className="carousel-genre-custom">{concert.message}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CarouselConcerts;
