//RecommendConcerts.js: Componente funcional para recomendar conciertos al usuario.

import React, { useEffect, useRef } from "react";
import { supabase } from "./supabaseClient";
import { generateConcertPrompt } from "../utils/prompts";

// Fetch all concerts from the database
const fetchConcerts = async () => {
  try {
    const today = new Date().toISOString().split("T")[0]; // Fecha actual en formato YYYY-MM-DD
    const { data: concerts, error } = await supabase
      .from("concerts")
      .select(
        `
        *,
        concert_band (
          band_id,
          bands (
            band_name
          )
        )
      `
      )
      .gte("day", today); // Filtrar conciertos futuros (mayor o igual a hoy)
    if (error) throw error;

    // Transform the data to include bands in a simpler format
    const transformedConcerts = concerts.map((concert) => ({
      ...concert,
      bands: concert.concert_band.map((cb) => cb.bands.band_name).join(", "),
    }));

    return transformedConcerts;
  } catch (error) {
    console.error("Error fetching future concerts:", error);
    throw new Error("Unable to fetch future concerts.");
  }
};

// Fetch existing recommendations for a user
const fetchExistingRecommendations = async (userId) => {
  try {
    const { data: recommendations, error } = await supabase
      .from("user_concert_recommendations")
      .select("*")
      .eq("user_id", userId);
    if (error) throw error;
    return recommendations;
  } catch (error) {
    console.error("Error fetching existing recommendations:", error);
    throw new Error("Unable to fetch existing recommendations.");
  }
};

// Check if user needs recommendations
const fetchNeedsRecommendation = async (userId) => {
  try {
    const { data: user, error } = await supabase
      .from("users")
      .select("needs_recommendation")
      .eq("user_id", userId)
      .single();

    if (error) throw error;

    return user?.needs_recommendation || false;
  } catch (error) {
    console.error("Error fetching needs_recommendation:", error);
    throw new Error("Unable to fetch user needs recommendation status.");
  }
};

// Fetch user preferences from Spotify or Supabase
const fetchUserPreferences = async (session, handleLogout) => {
  try {
    if (!session || !session.user) {
      throw new Error("No hay sesión activa.");
    }

    if (session.provider_token) {
      // El usuario se autenticó con Spotify, obtener datos desde la API de Spotify
      console.log("Obteniendo preferencias desde Spotify...");
      const response = await fetch(
        "https://api.spotify.com/v1/me/top/artists?time_range=medium_term&limit=10",
        { headers: { Authorization: `Bearer ${session.provider_token}` } }
      );

      if (response.status === 401) {
        console.warn(
          "Token de Spotify inválido o expirado. Redirigiendo al login..."
        );
        handleLogout();
        throw new Error("Token inválido o expirado.");
      }

      if (!response.ok) throw new Error("Failed to fetch user preferences.");

      const data = await response.json();
      return {
        genres: data.items.flatMap((artist) => artist.genres),
        artists: data.items.map((artist) => artist.name),
        dislikedGenres: [], // No se pueden obtener géneros no gustados desde Spotify
      };
    } else {
      // El usuario se registró con el formulario manual, obtener datos desde Supabase
      console.log("Obteniendo preferencias desde Supabase...");
      const { data: user, error } = await supabase
        .from("users")
        .select("top_genres, top_artists, disliked_genres")
        .eq("user_id", session.user.id)
        .single();

      if (error) {
        console.error(
          "Error obteniendo preferencias del usuario desde Supabase:",
          error
        );
        throw new Error("Unable to fetch user preferences from Supabase.");
      }

      return {
        genres: user.top_genres ? user.top_genres.split(", ") : [],
        artists: user.top_artists ? user.top_artists.split(", ") : [],
        dislikedGenres: user.disliked_genres
          ? user.disliked_genres.split(", ")
          : [],
      };
    }
  } catch (error) {
    console.error("Error en fetchUserPreferences:", error);
    throw new Error("Unable to fetch user preferences.");
  }
};

// Call OpenAI's API
const callOpenAI = async (prompt) => {
  try {
    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
      },
      body: JSON.stringify({
        model: "gpt-4o-mini",
        messages: [
          {
            role: "system",
            content: "You are an assistant for music recommendations.",
          },
          { role: "user", content: prompt },
        ],
        max_tokens: 5000,
        temperature: 0.7,
      }),
    });

    const aiData = await response.json();
    return aiData.choices[0]?.message?.content || "";
  } catch (error) {
    console.error("Error calling OpenAI API:", error);
    throw new Error("Unable to get recommendations from OpenAI.");
  }
};

// Process AI response into recommendations
const processAIResponse = (response, newConcerts, userId) => {
  const lines = response.split("\n").filter((line) => line.trim() !== "");
  const parsedRecommendations = [];

  lines.forEach((line, index) => {
    const match = line.match(
      /Concert ID:\s*(\d+);\s*Rating:\s*(\w+);\s*Reason:\s*(.+)/i
    );
    if (match) {
      parsedRecommendations.push({
        concert_id: parseInt(match[1], 10), // Extraer el Concert ID
        score: match[2].toLowerCase(),
        reason: match[3],
      });
    } else {
      console.warn(
        `Unrecognized format for line ${index + 1}: "${line.trim()}"`
      );
    }
  });

  return newConcerts.map((concert) => {
    const recommendation = parsedRecommendations.find(
      (rec) => rec.concert_id === concert.id
    );

    return {
      user_id: userId,
      concert_id: concert.id,
      score: recommendation?.score || "low",
      reason: recommendation?.reason || "Estoy pensando en ello...",
    };
  });
};

// Divide an array into smaller batches, prioritizing closest dates
const chunkArray = (array, size) => {
  // Sort the array by concert date (ascending order)
  const sortedArray = array.sort((a, b) => new Date(a.day) - new Date(b.day));

  const chunks = [];
  for (let i = 0; i < sortedArray.length; i += size) {
    chunks.push(sortedArray.slice(i, i + size));
  }
  return chunks;
};

// Process concerts in batches

const processConcertsInBatches = async (
  newConcerts,
  genres,
  dislikedGenres,
  artists,
  userId,
  setRecommendations
) => {
  const batchSize = 3; // Número de conciertos a procesar en cada lote
  const concertBatches = chunkArray(newConcerts, batchSize);

  for (const batch of concertBatches) {
    const prompt = generateConcertPrompt(
      genres,
      dislikedGenres,
      artists,
      batch
    ); // Generar el prompt
    const aiResponse = await callOpenAI(prompt);
    const recommendations = processAIResponse(aiResponse, batch, userId);

    await supabase.from("user_concert_recommendations").insert(recommendations);

    setRecommendations((prev) => {
      const updatedRecommendations = [...prev, ...recommendations];
      return updatedRecommendations.sort((a, b) => a.concert_id - b.concert_id);
    });
  }
};

// Main component to recommend concerts
const RecommendConcerts = ({ session, setRecommendations, handleLogout }) => {
  const isProcessing = useRef(false);

  const updateNeedsRecommendation = async (userId, needsRecommendation) => {
    try {
      const { error } = await supabase
        .from("users")
        .update({ needs_recommendation: needsRecommendation })
        .eq("user_id", userId);
      if (error) throw error;
    } catch (error) {
      console.error("Error updating needs_recommendation:", error);
    }
  };

  useEffect(() => {
    const fetchRecommendations = async () => {
      if (!session || !session.user || isProcessing.current) return; // Evita errores
      isProcessing.current = true;

      try {
        const userId = session.user.id;

        // Verificar si el usuario ya tiene recomendaciones
        const existingRecommendations = await fetchExistingRecommendations(
          userId
        );
        setRecommendations(existingRecommendations);

        const recommendedConcertIds = existingRecommendations.map(
          (rec) => rec.concert_id
        );

        // Obtener conciertos que aún no tienen recomendaciones
        const concerts = await fetchConcerts();
        const newConcerts = concerts.filter(
          (concert) => !recommendedConcertIds.includes(concert.id)
        );

        if (newConcerts.length > 0) {
          await updateNeedsRecommendation(userId, true);

          if (session.provider_token) {
            // Usuario registrado con Spotify
            console.log("Obteniendo datos desde Spotify...");
            const { genres, dislikedGenres, artists } =
              await fetchUserPreferences(session, handleLogout);
            await processConcertsInBatches(
              newConcerts,
              genres,
              dislikedGenres,
              artists,
              userId,
              setRecommendations
            );
          } else {
            // Usuario registrado con el formulario, usar datos de Supabase
            console.log("Obteniendo datos desde Supabase...");
            const { genres, dislikedGenres, artists } =
              await fetchUserPreferences(session, handleLogout);
            await processConcertsInBatches(
              newConcerts,
              genres,
              dislikedGenres,
              artists,
              userId,
              setRecommendations
            );
          }
        } else {
          await updateNeedsRecommendation(userId, false);
        }
      } catch (error) {
        console.error("Error fetching recommendations:", error);
      } finally {
        isProcessing.current = false;
      }
    };

    fetchRecommendations();
  }, [session, setRecommendations, handleLogout]);

  return null;
};

export default RecommendConcerts;
