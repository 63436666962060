import React, { useState } from "react";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";
import "../../blocks/registration/GenreSelection.css";

const FavoriteBands = ({ formData = {}, setFormData, completeOnboarding }) => {
  const navigate = useNavigate();
  const [bands, setBands] = useState(formData.bands || "");

  const getSession = async () => {
    console.log("Verificando sesión en Supabase...");
    const { data, error } = await supabase.auth.getSession();
    if (error) throw error;
    console.log("Sesión obtenida:", data);
    return data;
  };

  const updateUserData = async (userId) => {
    try {
      const { error } = await supabase
        .from("users")
        .update({ top_artists: bands })
        .eq("user_id", userId);

      if (error) {
        console.error("Error actualizando favorite bands en Supabase:", error);
        return false;
      }
      console.log("Bandas favoritas guardadas en Supabase correctamente");
      return true;
    } catch (err) {
      console.error("Error en updateUserData:", err);
      return false;
    }
  };

  const handleNext = async () => {
    try {
      const data = await getSession();
      if (!data || !data.session || !data.session.user) {
        alert("No hay sesión activa. Por favor, inicia sesión.");
        navigate("/");
        return;
      }

      console.log("Bandas favoritas ingresadas:", bands);
      if (typeof setFormData === "function") {
        setFormData({ ...formData, bands });
      }

      const user = data.session.user;
      const success = await updateUserData(user.id);
      if (success) {
        await completeOnboarding(); // ✅ Guardamos que el onboarding ha sido completado

        navigate("/myconcerts"); // Redirigir a la siguiente etapa del registro o al dashboard
      } else {
        alert("Hubo un problema al guardar las bandas favoritas.");
      }
    } catch (err) {
      console.error("Error en handleNext:", err);
      alert("Hubo un problema al procesar tu solicitud. Inténtalo de nuevo.");
    }
  };

  return (
    <div className="genre-selection-container">
      <h2>¿Cuáles son tus bandas favoritas? 🎤</h2>
      <textarea
        className="bands-textarea"
        value={bands}
        onChange={(e) => setBands(e.target.value)}
        placeholder="Puedes saltarte este paso, pero si quieres mejores recomendaciones, escribe los nombres de tus bandas favoritas"
      />
      <button className="next-button" onClick={handleNext}>
        Guardar
      </button>
      <button className="saltar-button" onClick={handleNext}>
        Saltar directo a los conciertos
      </button>
    </div>
  );
};

export default FavoriteBands;
